import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../Images/GSK_Logo.png';
import new_logo from '../Images/GSK_Logo_Full_Colour_RGB.png';
import { msalAuth, getUserName, getUserRoles } from '../msal/MsalAuthProvider';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    //Default COnstructor
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    //Toggle Navigation Bar
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    //Log Out command
    onlogout() {
        sessionStorage.removeItem("msal.userRoles");
        sessionStorage.removeItem("msal.userName");
        window.location.assign("https://logout.gsk.com");
    }

    //Renders the Navigation Menu 
    render() {

        let userRoles = getUserRoles();
        let hasAdminRole = userRoles.indexOf("Admin") > -1;
        let accessRole = "";
        if (hasAdminRole) { accessRole = "Admin" }
        else { accessRole = "User" }

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">Ware PP Campaign Booking Application</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Inventory Management
                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        <DropdownItem tag={Link} to={"/"}>
                                            Home
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={"/inventory-equipments"}>
                                            Equipment
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={"/inventory-rooms"}>
                                            Rooms
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={"/inventory-tooling"}>
                                            Tooling
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={"/inventory-ancillaries"}>
                                            Ancillaries
                                        </DropdownItem>
                                        {hasAdminRole &&
                                            <DropdownItem tag={Link} to={"/inventory-keywords"}>
                                                Keywords
                                            </DropdownItem>
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar key={"Booking"}>
                                    <DropdownToggle nav caret>
                                        Booking
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem tag={Link} to={"/non-smt-demand"}>
                                            Non-SMT Demand
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={"/booking-management"}>
                                            Booking Request
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={"/booking-history"}>
                                            Booking History
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar key={"User"}>
                                    <DropdownToggle nav caret>
                                        {getUserName() + " (" + accessRole + ")"}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={this.onlogout}>
                                            Logout
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </ul>
                        </Collapse>
                        {/* <img src={logo} style={{ width: "75px" }} /> */}
                        <img src={new_logo} style={{ width: "100px" }} />
                    </Container>
                </Navbar>
            </header>
        );
    }
}
