// TableContainer.js
import React from "react"
import { useTable, useSortBy, useFilters } from "react-table"
import { Table } from 'reactstrap';
import { Filter, DefaultColumnFilter } from './filter';

//Blueprint for all the tables. Search and Filter features are implemented below
const TableContainer = ({ columns, data }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        defaultColumn: { Filter: DefaultColumnFilter },
    },
        useFilters,
        useSortBy);
    let rowValue;
    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    return (

        // If you're curious what props we get as a result of calling our getter functions (getTableProps(), getRowProps())
        // Feel free to use console.log()  This will help you better understand how react table works underhood.
        <Table bordered hover {...getTableProps()} className={"table-container"}>

            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                <div {...column.getSortByToggleProps()} style={{ height: "50px"}}>
                                    {column.render('Header')}
                                    {generateSortingIndicator(column)}
                                </div>
                                {(column.Header !== "Action") && <Filter column={column} />}
                            </th>

                        ))}
                    </tr>
                ))}
            </thead>

            <tbody {...getTableBodyProps()}>            
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            })}
        
                        </tr>
                    )
                })}
            </tbody>

        </Table>
    )
}

export default TableContainer