import { Button } from 'bootstrap';
import React, { Component } from 'react';
import './../custom.css';
import SpaceModal from '@gsk-space-components/space-modal'
import SpaceButton from '@gsk-space-components/button';
import SpaceTheme from '@gsk-space-components/space-theme';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';
import TableContainer from "./TableContainer";
import { Container } from "reactstrap"


let accessToken = undefined;
export class KeywordInventory extends Component {
    static displayName = KeywordInventory.name;

    //Keyword Categories
    static keywordCategories = ["Air Handling Unit", "Ancillary", "Batch Type", "Campaign Type", "Batch Scale", "Equipment Type", "Notation", "Occupation Hazard", "Process", "Product or Compound ID", "Room Location", "Tooling Type"];

    //Default Categories
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            keywords: [],
            showAllKeywords: false,
            showAddEditKeyword: false,
            selectedKeywordID: 0,
            selectedKeywordCategory: "",
            selectedKeyword: "",
            deletionComment: "",
            loading: true,
            showAlert: false,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            showCategoryError: false,
            showKeywordError: false,
            showCommentError: false
        };

        this._submitKeyword = this._submitKeyword.bind(this);
        this._deleteKeyword = this._deleteKeyword.bind(this);
        this._validateKeywordInput = this._validateKeywordInput.bind(this);
    }

    //Component Did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://0c649c8d-4144-4538-a249-02bc7dcfaa25/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.populateKeywordsData();
    }

    //Renders the Keywords Table based on the existing Data
    renderKeywordsTable(keywords) {
        const data=this.state.keywords; 
        const columns=[
            { Header: "Category",
            accessor: "Category"},
            { Header: "Keyword",
            accessor: "Keyword"},
            {   
                width: 300,
                Header: "Action",
                accessor: "name",
                Cell: (data ) => (
                    
                    <div>
                <button title="Edit Equipment" className={"btn-edit"}  onClick={() => { 
                    this.setState({ showAllKeywords: false,
                     showAddEditKeyword: true, 
                     selectedKeywordID:  data.row.original.ID, 
                     selectedKeyword:  data.row.original.Keyword,
                      selectedKeywordCategory:  data.row.original.Category }); }}>Edit</button>
                <button title="Delete Equipment" className={"btn-delete"} onClick={() => {
                                    this.setState({
                                       showDeleteConfirmationDialog: true, 
                                       selectedKeywordID: data.row.original.ID,
                                    });
                                }}>Delete</button>
                </div>
                )}];
        return (
            <div>
                 {columns!==undefined}
                <Container style={{ marginTop: 50 }}>
                <TableContainer columns={columns} data={data} />
                </Container>
            </div>
        );
    }

    //Renders the new Keyword Screen
    renderNewKeywordScreen() {
        return (<div>
            <gsk-card image="/imagess/documentation.png" elevation="6">
                <div slot="content" className="gsk-card__content">
                    <div className="formcontainer">
                        <div className="formrow">
                            <div className="col-25">
                                <label >Category</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={KeywordInventory.keywordCategories}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder={"Select Category.."}
                                    value={this.state.selectedKeywordCategory}
                                    onChange={(_event, newValue) => { this.setState({ selectedKeywordCategory: newValue, showCategoryError: (newValue == undefined) }) }}
                                    error={this.state.showCategoryError}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Keyword</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} placeholder={"Enter keyword.."} error={this.state.showKeywordError} required value={this.state.selectedKeyword} onChange={(e) => { this.setState({ selectedKeyword: e.target.value, showKeywordError: (e.target.value === undefined || e.target.value === "") }); }}></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <button className={"submitBtn"} onClick={this._validateKeywordInput}>Submit</button>
                            <button className={"cancelBtn"} onClick={() => {
                                this.setState({
                                    showAddEditKeyword: false,
                                    showAllKeywords: true,
                                    showKeywordError: false,
                                    showCategoryError: false
                                });
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </gsk-card>
        </div >);
    }

    //Renders the Page based on New/Edit Keyword
    render() {
        return (
            <div>
                <h2 id="tabelLabel" >Inventory - Keywords</h2>
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllKeywords &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                            {this.state.isAdmin && <button title="Create New Keyword" className={"btn-add"} onClick={() => { this.setState({ showAllKeywords: false, showAddEditKeyword: true, selectedKeywordID: 0, selectedKeyword: "", selectedKeywordCategory: "" }); }}>New Keyword</button>}
                        </div>
                        <div className={"rootContent"}>{this.renderKeywordsTable(this.state.keywords)}</div>
                    </div>}
                {!this.state.loading && this.state.showAddEditKeyword && <div>{this.renderNewKeywordScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Keyword"
                    body="Would you like to save this keyword?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitKeyword}
                    secondaryButtonFunction={() => { this.setState({ showAddEditKeyword: false, showAllKeywords: true, showConfirmationDialog: false }) }}
                    id="confirm-save-keyword"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Keyword"
                    body={<div><div>Would you like to delete this keyword?</div><div>
                        <OutlinedInput fullWidth={true} placeholder={"Enter reason.."} required multiline={true} rows={3} error={this.state.showCommentError}
                            onChange={(e) => { this.setState({ deletionComment: e.target.value, showCommentError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div></div>}
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._deleteKeyword}
                    secondaryButtonFunction={() => { this.setState({ showAddEditKeyword: false, showAllKeywords: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="confirm-delete-keyword"
                />}
                {this.state.showAlert && <SpaceModal
                    open={this.state.showAlert}
                    titleText="Error Occured"
                    body="Error Occured. Please Contact the Administrator"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAlert: false }) }}
                    id="alertBox"
                />}
            </div >
        );
    }

    //Populates all the keywords
    async populateKeywordsData() {
        const response = await fetch('Keywords', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, keywords: data, loading: false, showAllKeywords: true, showAddEditKeyword: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedKeywordID: 0 });
        }
        else {
            this.setState({ isAdmin: false, keywords: [], loading: false, showAllKeywords: true, showAddEditKeyword: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedKeywordID: 0 });
        }
    }

    //Validates the Input provided by User
    _validateKeywordInput() {
        let canSave = true;
        if (!this.state.selectedKeywordCategory) {
            this.setState({ showCategoryError: true });
            canSave = false;
        }
        if (!this.state.selectedKeyword) {
            this.setState({ showKeywordError: true });
            canSave = false;
        }
        if (canSave) {
            this.setState({ showCategoryError: false, showKeywordError: false, showConfirmationDialog: true });
        }
    }

    //Submits the Keyword
    _submitKeyword() {
        let selectedCategory = this.state.selectedKeywordCategory;
        let enteredkeyword = this.state.selectedKeyword;
        let keyword = {
            "Category": selectedCategory,
            "Keyword": enteredkeyword,
            "ID": this.state.selectedKeywordID
        };

        this._postKeyword(keyword);
    }

    //Creates the Keyword in the Inventory
    async _postKeyword(keyword) {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(keyword)
        };
        await fetch("Keywords", requestOptions).then((response) => {
            if (response.ok) {
                this.populateKeywordsData();
            } else {
                this.setState({
                    showAlert: true
                });
                this.populateKeywordsData();
            }
        })
    }

    //Deletes the Keyword from Inventory
    _deleteKeyword() {
        if (this.state.deletionComment) {
            fetch("Keywords/" + this.state.selectedKeywordID + "?comment=" + this.state.deletionComment, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken,
                })
            }).then(_ => {
                this.populateKeywordsData();
            }).catch(console.log);
        }
        else {
            this.setState({ showCommentError: true });
        }
    }

}
