import { Button } from 'bootstrap';
import React, { Component } from 'react';
import ReactDOMServer from "react-dom/server";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import SpaceModal from '@gsk-space-components/space-modal'
import SpaceButton from '@gsk-space-components/button';
import SpaceTheme from '@gsk-space-components/space-theme';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';
import { withRouter, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import './../custom.css';

let accessToken = undefined;
let storedAncillaries = [];
let storedTools = [];
let storedNames = [];
let storedProcess = [];
let storedRooms = [];
let storedSupply = [];
let action = "";
let storedInformation = [];
let supplyData = "";
let processData = "";
let equipmentsData = "";
let roomsData = "";
let ancillariesData = "";
let ancillariesQuantity = "";
let toolsData = "";
let dataMaster = [];
let productId = "";
let compoundType = "";
let noOfbatches = "";
let releaseDate = "";
let requestDate = "";
let dueDate = "";
let dropDeaddate = "";
let campaignPriority = "";
let batchScale = "";
let processBy = "";
let stepIndex = "";
let durationPerBatch = "";
let equipmentUID = "";
let equipmentName = "";
let reqFlexibleRoom = "";
let roomUID = "";
let roomLocation = "";
let roomName = "";
let roomNumber = "";
let requestorName = "";
let includesPlacebo = "";
let campaignType = "";
let safetyBookletno = "";
let occupationHazard = "";
let notation = "";
let batchType = "";
let batchSize = "";
let earliestStartDate = "";
let testingLeadTime="";
let equipmentChangePart="";
let operatorAssigned="";

export class ReviewBooking extends Component {

    static displayName = ReviewBooking.name;

    //Saves the Booking Request as template
    onSubmitAsTemplate = () => {
        this.setState({ showConfirmationDialog: false })
        this.removeStorage();

        action = localStorage.getItem('Action');
        if (this.state.campaignMaster !== null) {
            this.state.campaignMaster.Status = "Submitted";
            this.state.campaignMaster.Template = true;
            this._postCampaignData(this.state.campaignMaster, this.state.decisionMaster, this.state.routesMaster);
        }
    }

    //Submits the Booking Request
    onSubmit = () => {
        this.setState({ showConfirmationDialog: false, showDeleteConfirmationDialog: false })
        this.removeStorage();

        action = localStorage.getItem('Action');
        if (this.state.campaignMaster !== null) {
            this.state.campaignMaster.Status = "Submitted";
            this._postCampaignData(this.state.campaignMaster, this.state.decisionMaster, this.state.routesMaster);
        }
    }

    //Cancelling the Recent Changes
    onCancel = () => {
        this.setState({ showCancellationDialog: false })
        this.removeStorage();

        const page = localStorage.getItem('Page');
        if (page === 'Template') {
            this.props.history.push('/booking-history');
        } else {
            this.props.history.push('/booking-management');
        }
    }

    //Ignoring the Cancel Changes
    ignoreCancel = () => {
        this.setState({ showCancellationDialog: false})
    }

    //Shows Confirmation Dialog
    openDialog(){
        this.setState({ showConfirmationDialog: true })
    }

    //Shows Cancellation Dialog
    cancelDialog() {
        this.setState({ showCancellationDialog: true })
    }

    //Export the PDF
    onDownload = () => {
        
        const input = document.getElementById('reviewObject');
        html2canvas(input)
            .then((canvas) => {
                const pdf = new jsPDF("p", "mm", "a3");
                var img = new Image();
                img.src = canvas.toDataURL();
                pdf.addImage(img, 'image/png', 10, 10, 0, 0);
                pdf.save("Booking Request.pdf");
            });

    }

    //Removes the Local Storage
    removeStorage(){
        localStorage.removeItem('CampaignId');
        localStorage.removeItem('UserFixedDecision');
        localStorage.removeItem('ProdctionRoutes');
        localStorage.removeItem('SupplyBooking');
        localStorage.removeItem('ToolingsBooking');
        localStorage.removeItem('Equipments');
        localStorage.removeItem('ProcessBookings');
        localStorage.removeItem('RoomBookings');
        localStorage.removeItem('AncillariesBooking');
        localStorage.removeItem('RoomsBooking');
    }

    //Modify the Booking Request
    modify = () => {
        window.location.reload();

    }

    //Saves the Booking Request as Draft
    saveStatus = () => {
        action = localStorage.getItem('Action');
        if (this.state.campaignMaster !== null) {
            this.state.campaignMaster.Status = "Draft";
            this._postCampaignData(this.state.campaignMaster, this.state.decisionMaster, this.state.routesMaster);
        }

    }

    //Changes the Format to GSK
    changeDateFormat = (date) => {
        let dateFormat = "";
        var day = new Date(date).getDate();
        if (day < 10) {
            day = '0' + day;
        }
        dateFormat = day + '-' + (new Date(date).toLocaleString('default', { month: 'short' })) + '-' + new Date(date).getFullYear();
        return dateFormat.toString();
    }

    //Default Constructor
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: true,
            supply: [],
            information: [],
            showAllSupply: true,
            toolings: [],
            showAllToolings: true,
            ancillaries: [],
            showAllAncillaries: true,
            equipments: [],
            showAllEquipments: true,
            process: [],
            showAllProcess: true,
            rooms: [],
            showAllRooms: true,
            loading: false,
            decisionMaster: [],
            campaignMaster: [],
            routesMaster: [],
            showConfirmationDialog: false,
            showCancellationDialog: false,
            showAlert: false,
            showSuccess: false,
            routesMaster: [],
            status: ""
            };
            this.openDialog = this.openDialog.bind(this)
            this.cancelDialog = this.cancelDialog.bind(this)

    }

    //Component Did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://0c649c8d-4144-4538-a249-02bc7dcfaa25/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.loadSupplyData();
        this.loadadditionalData();
        this.loadProcessData();
        this.loadAncillaryData();
        this.loadToolingsData();
        this.fix_decisions();
        this.campaignData();
        this.productionRoutes();

    }

    //Renders the Page using all the Details from Different Tabs
    render() {
        return (
            <div>
                <div>
                    <div style={{
                        textAlign: "right",
                        marginBottom: "30px",
                        marginLeft: "30px",
                        marginRight: "30px"
                    }}>
                        <button onClick={this.onDownload} title="Download PDF" className={"btn-add"} >Download Request</button>
                    </div>
                </div>
                <div id="reviewObject" style={{
                    marginTop: "30px",
                    marginLeft: "30px",
                    marginRight: "30px" }}>
                    <h4 id="tabelLabel" >Booking - Supply Requirement</h4>
                    {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                    {!this.state.loading && this.state.showAllSupply &&
                        <div>
                            <div className={"rootContent"}>{this.rendersupplyTable(this.state.supply, this.state.information)}</div>
                        </div>}
                    <h4 id="tabelLabel" >Booking - Additional Information</h4>
                    {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                    {!this.state.loading && this.state.showAllSupply &&
                        <div>
                        <div className={"rootContent"}>{this.renderAdditionalInformationTable(this.state.supply, this.state.information)}</div>
                        </div>}

                    <h4 id="tabelLabel" >Booking - Process</h4>
                    {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                    {!this.state.loading && this.state.showAllProcess &&
                        <div>
                            <div className={"rootContent"}>{this.renderprocessTable(this.state.process)}</div>
                        </div>}

                    <h4 id="tabelLabel" >Booking - Tooling</h4>
                    {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                    {!this.state.loading && this.state.showAllToolings &&

                        <div className={"rootContent"}>{this.renderToolingTable(this.state.toolings)}
                        </div>
                    }
                    <h4 id="tabelLabel" >Booking - Ancillaries</h4>
                    {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                    {!this.state.loading && this.state.showAllAncillaries &&
                        <div>
                            <div className={"rootContent"}>{this.renderancillariesTable(this.state.ancillaries)}</div>
                        </div>}
                </div>
                <div>
                    <div style={{ textAlign: "right",
                        marginBottom: "30px",
                        marginLeft: "30px",
                        marginRight: "30px"
                    }}>
                        <button style={{ 'margin-right': 70 }} onClick={this.cancelDialog} title="Cancel" className={"reviewBtn"} >Cancel Changes</button>

                        <button style={{ 'margin-right': 70 }} onClick={this.modify} title="Modify" className={"btn-add"} >Modify</button>
                        <button style={{ 'margin-right': 70 }} onClick={this.saveStatus} title="Save As Draft" className={"btn-add"} >Save as Draft</button>

                        <button onClick={this.openDialog} title="Submit" className={"btn-add"} >Submit</button>
                    </div>
                </div>

                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save as Editable Copy"
                    body="Would you like to create the editable copy of this Booking Request?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this.onSubmitAsTemplate}
                    secondaryButtonFunction={this.onSubmit}
                    id="saveEquipDialog"
                />}

                {this.state.showCancellationDialog && <SpaceModal
                    open={this.state.showCancellationDialog}
                    titleText="Cancel Changes"
                    body="Would you like to Cancel the changes made?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this.onCancel}
                    secondaryButtonFunction={this.ignoreCancel}
                    id="cancelDialog"
                />}
                {this.state.showAlert && <SpaceModal
                    open={this.state.showAlert}
                    titleText="Error Occured"
                    body="Error Occured. Please Contact the Administrator"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => {
                        this.setState({ showAlert: false });
                        const page = localStorage.getItem('Page');
                        if (page === 'Template') {
                            this.props.history.push('/booking-history');
                        } else {
                            this.props.history.push('/booking-management');
                        }
                    }}
                    id="alertBox"
                />}
                {this.state.showSuccess && <SpaceModal
                    open={this.state.showSuccess}
                    titleText="Request Created"
                    body="The Request has been created Successfully"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => {
                        this.setState({ showSuccess: false });
                        const page = localStorage.getItem('Page');
                        if (page === 'Template') {
                            this.props.history.push('/booking-history');
                        } else {
                            this.props.history.push('/booking-management');
                        }
                    }}
                    id="alertBox"
                />}
            </div>
        );
    }

    //Renders the Supply Requirement Component
    rendersupplyTable(supply, information) {
        return (
            <div>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Campaign ID</th>
                            <th>Requestor Name</th>
                            <th>Product/Compound ID</th>
                            <th>Compound Type</th>
                            <th>Campaign Type</th>
                            <th>Process By</th>
                            <th>Includes Placebo</th>
                            <th>Request Date</th>
                            <th>Safety Booklet Number</th>
                            <th>Occupation Hazard</th>
                            <th>Notation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(supply === undefined || supply === null || supply.length === 0) && <tr><td colSpan={19}><div className={"no-record-error"}>No records found</div></td></tr>}
                        {supply.map(supply =>
                            <tr>
                                <td>{supply.Campaign_ID}</td>
                                <td>{supply.RequestorName}</td>
                                <td>{supply.Compound_ID}</td>
                                <td>{supply.Compound_Type}</td>
                                <td>{supply.Campaign_Type}</td>
                                <td>{supply.ProcessBy}</td>
                                <td>{supply.IncludesPlacebo ? "Yes" : "No"}</td>
                                <td>{this.changeDateFormat(supply.RequestDate)}</td>
                                <td>{supply.SafetyBookletNumber}</td>
                                <td>{supply.OccupationHazard}</td>
                                <td>{supply.Notation}</td>

                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    //Renders the Additional Information Component
    renderAdditionalInformationTable(supply, information) {
        return (
            <div>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Campaign Priority</th>
                            <th>Batch Type</th>
                            <th>Batch Scale</th>
                            <th>Number of Batches</th>
                            <th>Batch Size</th>
                            <th>Earliest Start Date</th>
                            <th>Due Date</th>
                            <th>Drop Dead Date</th>
                            <th>Testing Lead Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(information === undefined || information === null || information.length === 0) && <tr><td colSpan={19}><div className={"no-record-error"}>No records found</div></td></tr>}
                        {information.map(information =>
                            <tr>
                                <td>{information.Priority}</td>
                                <td>{information.BatchType}</td>
                                <td>{information.BatchScale}</td>
                                <td>{information.NumberOfBatches}</td>
                                <td>{information.BatchSize}</td>
                                <td>{this.changeDateFormat(information.EarliestStartDate)}</td>
                                <td>{this.changeDateFormat(information.DueDate)}</td>
                                <td>{this.changeDateFormat(information.DropDeadDate)}</td>
                                <td>{information.TestingLeadTime}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    //Renders the Process Table Component
    renderprocessTable(process) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Process UID</th>
                        <th>No of Operator Assigned</th>
                        <th>Step Index</th>
                        <th>Duration Per Batch</th>
                        <th>Equipment UID</th>
                        <th>Equipment Name</th>
                        <th>Equipment Change Part</th>
                        <th>Requires Flexible Room?</th>
                        <th>Room UID</th>
                        <th>Room Location</th>
                        <th>Room Number</th>

                    </tr>
                </thead>
                <tbody>
                    {(process === undefined || process === null || process.length === 0) && <tr><td colSpan={16}><div className={"no-record-error"}>No records found</div></td></tr>}
                    {process.map(process =>
                        <tr>
                            <td>{process.ProcessUID}</td>
                            <td>{process.OperatorAssigned}</td>
                            <td>{process.StepIndex}</td>
                            <td>{process.DurationPerBatch}</td>
                            <td>{process.EquipmentUID}</td>
                            <td>{process.EquipmentName}</td>
                            <td>{process.EquipmentChangePart}</td>
                            <td>{process.RequiresFlexibleRoom}</td>
                            <td>{process.RoomUID}</td>
                            <td>{process.RoomLocation}</td>
                            <td>{process.RoomNumber}</td>

                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    //Renders the Tooling Table Component
    renderToolingTable(toolings) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Set Number</th>
                        <th>Tooling Type</th>
                        <th>Tooling Name</th>
                        <th>No of Upper Punches</th>
                        <th>No of Lower Punches</th>
                        <th>Number of Dies</th>
                    </tr>
                </thead>
                <tbody>
                    {(toolings === undefined || toolings === null || toolings.length === 0) && <tr><td colSpan={8}><div className={"no-record-error"}>No records found</div></td></tr>}
                    {toolings.map(tooling =>
                        <tr>
                            <td>{tooling.SetNumber}</td>
                            <td>{tooling.ToolingType}</td>
                            <td>{tooling.ToolingName}</td>
                            <td>{tooling.UpperPunches}</td>
                            <td>{tooling.LowerPunches}</td>
                            <td>{tooling.NumberOfDies}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    //Renders the Ancillary Table Component
    renderancillariesTable(ancillaries) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Ancillary ID</th>
                        <th>Ancillary</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {(ancillaries === undefined || ancillaries === null || ancillaries.length === 0) && <tr><td colSpan={4}><div className={"no-record-error"}>No records found</div></td></tr>}
                    {ancillaries.map(ancillary =>
                        <tr>
                            <td>{ancillary.AncillaryID}</td>
                            <td>{ancillary.Ancillary}</td>
                            <td>{ancillary.Quantity}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    //Loads the Additional Data Details
    loadadditionalData() {
        storedInformation = JSON.parse(localStorage.getItem("InformationBooking") || '{}');
        if (storedInformation !== null && storedInformation !== "" && Object.entries(storedInformation).length !== 0) {

            campaignPriority = storedInformation[0].Priority;
            batchType = storedInformation[0].BatchType;
            batchScale = storedInformation[0].BatchScale
            noOfbatches = storedInformation[0].NumberOfBatches;
            batchSize = storedInformation[0].BatchSize;
            dueDate = storedInformation[0].DueDate;
            earliestStartDate = storedInformation[0].EarliestStartDate;
            dropDeaddate = storedInformation[0].DropDeadDate;
            testingLeadTime=storedInformation[0].TestingLeadTime;
            this.setState({ information: storedInformation, loading: false })
        } else {
            this.setState({ information: [], loading: false })
        }
    }

    //Loads the Supply Data Details
    loadSupplyData() {
        action = localStorage.getItem("Status");
        storedSupply = JSON.parse(localStorage.getItem("SupplyBooking") || '{}');
        if (storedSupply !== null && storedSupply !== "" && Object.entries(storedSupply).length !== 0) {
            supplyData = storedSupply[0].Campaign_ID;
            requestorName = storedSupply[0].RequestorName;
            productId = storedSupply[0].Compound_ID;
            compoundType = storedSupply[0].Compound_Type;
            campaignType = storedSupply[0].Campaign_Type;
            processBy = storedSupply[0].ProcessBy;
            includesPlacebo = storedSupply[0].IncludesPlacebo;
            requestDate = storedSupply[0].RequestDate;
            safetyBookletno = storedSupply[0].SafetyBookletNumber;
            occupationHazard = storedSupply[0].OccupationHazard;
            notation = storedSupply[0].Notation;
            this.setState({ supply: storedSupply, loading: false })
        } else {
            this.setState({ supply: [], loading: false })
        }
    }

    //Loads the Process Data Details
    loadProcessData() {

        storedProcess = JSON.parse(localStorage.getItem("ProcessBookings") || '{}');
        if (storedProcess !== null && storedProcess !== "" && Object.entries(storedProcess).length !== 0) {
            processData = storedProcess
                .map(function (item, i) {
                    return item["ProcessUID"];
                }).join("||");
            stepIndex
                = storedProcess.map(itm => itm.StepIndex.toString()).join("||");
            durationPerBatch = storedProcess.map(itm => itm.DurationPerBatch.toString()).join("||");
            equipmentUID
                = storedProcess
                    .map(function (item, i) {
                        return item["EquipmentUID"];
                    }).join("||");
            equipmentName
                = storedProcess
                    .map(function (item, i) {
                        return item["EquipmentName"];
                    }).join("||");
            reqFlexibleRoom
                = storedProcess
                    .map(function (item, i) {
                        return item["RequiresFlexibleRoom"];
                    }).join("||");
            roomUID
                = storedProcess
                    .map(function (item, i) {
                        return item["RoomUID"];
                    }).join("||");
            roomLocation
                = storedProcess
                    .map(function (item, i) {
                        return item["RoomLocation"];
                    }).join("||");
            roomName
                = storedProcess
                    .map(function (item, i) {
                        return item["RoomName"];
                    }).join("||");

            roomNumber
                = storedProcess
                    .map(function (item, i) {
                        return item["RoomNumber"];
                    }).join("||");
            
            equipmentChangePart=
            storedProcess .map(function (item, i) {
                return item["EquipmentChangePart"];
            }).join("||");
            operatorAssigned =
                storedProcess.map(function (item, i) {
                    return item["OperatorAssigned"];
                }).join("||");
            this.setState({ process: storedProcess, loading: false })
        } else {
            this.setState({ process: [], loading: false })
        }
    }

    //Loads the Ancillary Data Details
    loadAncillaryData() {

        storedAncillaries = JSON.parse(localStorage.getItem("AncillariesBooking") || '{}');
        if (storedAncillaries !== null && storedAncillaries !== "" && Object.entries(storedAncillaries).length !== 0) {
            ancillariesData = storedAncillaries
                .map(function (item, i) {
                    return item["AncillaryID"];
                }).join("||");
            ancillariesQuantity = storedAncillaries
                .map(function (item, i) {
                    return item["Quantity"];
                }).join("||");
            this.setState({ ancillaries: storedAncillaries, loading: false })
        } else {

            this.setState({ ancillaries: [], loading: false })
        }
    }

    //Loads the Tooling Data Details
    loadToolingsData() {
        storedTools = JSON.parse(localStorage.getItem("ToolingsBooking") || '{}');
        if (storedTools !== null && storedTools !== "" && Object.entries(storedTools).length !== 0) {
            toolsData = storedTools
                .map(function (item, i) {
                    return item["SetNumber"];
                }).join("||");
            this.setState({ toolings: storedTools })
        } else {
            this.setState({ toolings: [] })
        }
    }

    //Maps the User Fixed Decision Details
    fix_decisions() {
        if (processData.length > 0) {
            let dataMaster = {
                "Campaign_UID": supplyData,
                "Process_UID": processData,
                "No_of_Operator": operatorAssigned,
                "Equipment_Assigned": equipmentUID,
                "Equipent_Name": equipmentName,
                "Tooling_Assigned": toolsData,
                "Ancillary_Assigned": ancillariesData,
                "Ancillary_Quantity": ancillariesQuantity,
                "Requires_Flexible_Room": reqFlexibleRoom,
                "Room_Assigned": roomUID,
                "Room_Location": roomLocation,
                "Room_Number": roomNumber,
                "ChangePart_Assigned": equipmentChangePart
            };
            this.setState({ decisionMaster: dataMaster })
        }
    }

    //Creates the User Fixed Decision Details
    async _postUserFixedDecision(decisionMaster) {
        debugger;
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(decisionMaster)
        };
        await fetch("Booking/SaveUserFixedDecision", requestOptions).then((response) => {
            if (!response.ok) {
                this.setState({
                    showAlert: true
                });
            } else {
                this.setState({
                    showSuccess: true
                });
            }
        });
    }


    //Maps the campaign table details
    campaignData() {
        let campaignData = {
            "Campaign_ID": supplyData,
            "Compound_ID": productId,
            "Compound_Type": compoundType,
            "Campaign_Type": campaignType,
            "NumberOfBatches": Number(noOfbatches),
            "EarliestStartDate": new Date(earliestStartDate),
            "DueDate": new Date(dueDate),
            "DropDeadDate": new Date(dropDeaddate),
            "ProcessBy": processBy,
            "IncludesPlacebo": Boolean(includesPlacebo),
            "SafetyBookletNumber": safetyBookletno,
            "OccupationHazard": occupationHazard,
            "Notation": notation,
            "Priority": campaignPriority,
            "BatchType": batchType,
            "BatchSize": batchSize,
            "RequestorName": requestorName,
            "BatchScale": batchScale,
            "RequestDate": new Date(requestDate),
            "Status": "",
            "TestingLeadTime": Number(testingLeadTime),
            "Template": false,
        };

        this.setState({ campaignMaster: campaignData })

    }

    //Creates the Campaign Data
    async _postCampaignData(campaignMaster, decisionMaster, routesMaster) {
        debugger;
        if (action === "Add" || action === "Template") {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(campaignMaster)
            };
            await fetch("Booking/SaveCampaignTable", requestOptions).then((response) => {
                if (response.ok) {
                    this._postRoutesData(routesMaster, decisionMaster);
                } else {
                    this.setState({
                        showAlert: true
                    });
                }
            })
        } else {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(campaignMaster)
            };
            await fetch("Booking/UpdateCampaignTable", requestOptions).then((response) => {
                if (response.ok) {
                    this._postRoutesData(routesMaster, decisionMaster);
                } else {
                    this.setState({
                        showAlert: true
                    });
                }
            })
        }
    }

    //Maps the production routes table
    productionRoutes() {
        if (processData.length > 0) {
            let routesData = {
                "Campaign_UID": supplyData,
                "Process_UID": processData,
                "Step_Index": stepIndex,
                "Dur_Days_Per_Batch": durationPerBatch
            };

            this.setState({ routesMaster: routesData })
        }
    }

    //Creates the Production Routes details
    async _postRoutesData(routesMaster, decisionMaster) {
        if (Object.entries(storedProcess).length > 0) {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(routesMaster)
            };
            await fetch("Routes/CreateOrUpdateRoute", requestOptions).then((response) => {
                if (response.ok) {
                    this._postUserFixedDecision(decisionMaster);
                } else {
                    this.setState({
                        showAlert: true
                    });
                }
            });
        }
        else {
            this.setState({
                showSuccess: true
            });
        }
    }
}