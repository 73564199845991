import { Button } from 'bootstrap';
import React, { Component } from 'react';
import './../custom.css';
import SpaceModal from '@gsk-space-components/space-modal'
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';
import TableContainer from "./TableContainer";
import { defaultColumn } from 'react-table';
import ReactTable from "react-table";
import { Container } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { error } from 'jquery';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

let accessToken = undefined;
export class RoomInventory extends Component {
    static displayName = RoomInventory.name;

    //Default Constructor
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            rooms: [],
            roomLocationKeywords: [],
            showAllRooms: false,
            showAddEditRoom: false,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            roomuid: "",
            zoneuid: "",
            location: "",
            roomNumber: "",
            capacity: undefined,
            selectedRoomID: 0,
            showAlert: false,
            showRoomUidError: false,
            showZoneUidError: false,
            showLocationError: false,
            showRoomNumberError: false,
            showCapacityError: false,
            deletionComment: "",
            showCommentError: false,
            loading: true
        };

        this._submitRoom = this._submitRoom.bind(this);
        this._deleteRoom = this._deleteRoom.bind(this);
        this._validateRoomInput = this._validateRoomInput.bind(this);

    }

    //Exports the PDF Report
    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        const title = "Rooms Report";
        const headers = [["Room UID", "Room Location", "Room Number", "Zone UID", "Capacity"]];
        const data = this.state.rooms.map(rm => [rm.RoomUID, rm.Location, rm.RoomNumber, rm.ZoneUID, rm.Capacity]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Rooms Report.pdf")
    }

    //Component did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://0c649c8d-4144-4538-a249-02bc7dcfaa25/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.populateRoomsData();
        this._getRoomLocationKeywords();
    }

    //Renders the page based on New/Edit Request
    render() {
        return (
            <div>
                <h2 id="tabelLabel" >Inventory - Rooms</h2>
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllRooms &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                            {this.state.isAdmin && <button title="Create New Room" className={"btn-add"} onClick={() => {
                                this.setState({
                                    showAllRooms: false,
                                    showAddEditRoom: true,
                                    roomuid: "",
                                    zoneuid: "",
                                    location: "",
                                    roomNumber: "",
                                    capacity: undefined,
                                    selectedRoomID: 0
                                });
                        }}>New Room</button>}
                        <button onClick={this.exportPDF} title="Download PDF" className={"btn-add"} >Download PDF</button>
                        </div>
                        <div className={"rootContent"}>{this.renderRoomsTable(this.state.rooms)}</div>
                    </div>}
                {!this.state.loading && this.state.showAddEditRoom && <div>{this.renderNewRoomScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Room"
                    body="Would you like to save this Room?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitRoom}
                    secondaryButtonFunction={() => { this.setState({ showAddEditRoom: false, showAllRooms: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="saveEquipDialog"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Room"
                    body={<div><div>Would you like to delete this Room?</div><div>
                        <OutlinedInput fullWidth={true} placeholder={"Enter reason.."} required multiline={true} rows={3} error={this.state.showCommentError}
                            onChange={(e) => { this.setState({ deletionComment: e.target.value, showCommentError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div></div>}
                    mainButtonLabel="No"
                    secondaryButtonLabel="Yes"
                    secondaryButtonFunction={this._deleteRoom}
                    mainButtonFunction={() => { this.setState({ showAddEditRoom: false, showAllRooms: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="deleteEquipDialog"
                />}
                {this.state.showAlert && <SpaceModal
                    open={this.state.showAlert}
                    titleText="Error Occured"
                    body="Error Occured. Please Contact the Administrator"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAlert: false }) }}
                    id="alertBox"
                />}
            </div>
        );
    }

    //Renders existing details from the inventory
    renderRoomsTable(rooms) {
        debugger;
        const data = this.state.rooms;
        const admin = this.state.isAdmin;
        let columns = [];
        if (admin) {
            columns = [
                {
                    Header: "Room UID",
                    accessor: "RoomUID"
                },
                {
                    Header: "Room Location",
                    accessor: "Location"
                },
                {
                    Header: "Room Number",
                    accessor: "RoomNumber"
                },
                {
                    Header: "Zone UID",
                    accessor: "ZoneUID"
                },
                {
                    Header: "Capacity",
                    accessor: "Capacity"
                },
                {
                    width: 300,
                    Header: "Action",
                    accessor: "name",
                    Cell: (data) => (

                        <div>
                            <button title="Edit Equipment" className={"btn-edit"} onClick={() =>
                                this.setState({
                                    showAllRooms: false,
                                    showAddEditRoom: true,
                                    selectedRoomID: data.row.original.ID,
                                    roomuid: data.row.original.RoomUID,
                                    location: data.row.original.Location,
                                    roomNumber: data.row.original.RoomNumber,
                                    zoneuid: data.row.original.ZoneUID,
                                    capacity: data.row.original.Capacity
                                })}>
                                Edit </button>
                            <button title="Delete Equipment" className={"btn-delete"} onClick={() => {
                                this.setState({
                                    showDeleteConfirmationDialog: true,
                                    selectedRoomID: data.row.original.ID,
                                });
                            }}>Delete</button>
                        </div>
                    )
                }
            ];
        } else {
            columns = [
                {
                    Header: "Room UID",
                    accessor: "RoomUID"
                },
                {
                    Header: "Room Location",
                    accessor: "Location"
                },
                {
                    Header: "Room Number",
                    accessor: "RoomNumber"
                },
                {
                    Header: "Zone UID",
                    accessor: "ZoneUID"
                },
                {
                    Header: "Capacity",
                    accessor: "Capacity"
                }
            ];
        }
        return (
            <div>
                {columns !== undefined}
                <Container style={{ marginTop: 50 }}>
                    <TableContainer columns={columns} data={data} />
                </Container>

            </div>
        );
    }

    //Renders New Screen
    renderNewRoomScreen() {
        return (<div>
            <div className="formcontainer">
                <div className="formrow">
                    <div className="col-25">
                        <label >Room UID</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Room UID.."} value={this.state.roomuid} error={this.state.showRoomUidError}
                            onChange={(e) => { this.setState({ roomuid: e.target.value, showRoomUidError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Room Location</label>
                    </div>
                    <div className="col-75">
                        <SpaceAutocomplete
                            id="roomLocation"
                            options={this.state.roomLocationKeywords.map(itm => itm.Keyword)}
                            getOptionLabel={(option) => option}
                            fullWidth={true}
                            placeholder="Select Room Location.."
                            value={this.state.location}
                            onChange={(_event, newValue) => { this.setState({ location: newValue, showLocationError: (newValue === undefined || newValue === "") }) }}
                            error={this.state.showLocationError}
                        />
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Room Number</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Room Number.."} value={this.state.roomNumber} error={this.state.showRoomNumberError}
                            onChange={(e) => { this.setState({ roomNumber: e.target.value, showRoomNumberError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Zone UID</label>
                    </div>
                    <div className="col-75">
                        <SpaceAutocomplete
                            id="room-zone"
                            options={["OSD", "Inhaled"]}
                            getOptionLabel={(option) => option}
                            fullWidth={true}
                            placeholder="Select Zone UID.."
                            value={this.state.zoneuid}
                            error={this.state.showZoneUidError}
                            onChange={(_event, newValue) => { this.setState({ zoneuid: newValue, showZoneUidError: (newValue === undefined || newValue === "") }) }}
                        />
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Capacity</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput type="number" fullWidth={true} required placeholder={"Enter Capacity.."} value={this.state.capacity} error={this.state.showCapacityError}
                            onChange={(e) => { this.setState({ capacity: (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.capacity, showCapacityError: (!(Number(e.target.value) && e.target.value > 0) && !this.state.capacity) }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <button className={"submitBtn"} onClick={this._validateRoomInput}>Submit</button>
                    <button className={"cancelBtn"} onClick={() => {
                        this.setState({
                            showAddEditRoom: false,
                            showAllRooms: true,
                            showRoomUidError: false,
                            showRoomNumberError: false,
                            showLocationError: false,
                            showZoneUidError: false,
                            showCapacityError: false
                        });
                    }}>Cancel</button>
                </div>
            </div>
        </div >);
    }

    //Gets the Room Location Keywords
    async _getRoomLocationKeywords() {
        const response = await fetch('Keywords/Room Location', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const roomLocationData = await response.json();
            this.setState({ roomLocationKeywords: roomLocationData });
        }
        else {
            this.setState({ roomLocationKeywords: [] });
        }
    }

    //Gets all the Room Details from Inventory
    async populateRoomsData() {
        const response = await fetch('Rooms', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, rooms: data, loading: false, showAllRooms: true, showAddEditRoom: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false });
        }
        else {
            this.setState({ isAdmin: false, rooms: [], loading: false, showAllRooms: true, showAddEditRoom: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false });
        }
    }

    //Validates the Input provided by User
    _validateRoomInput() {
        let canSave = true;
        if (!this.state.roomuid) {
            this.setState({ showRoomUidError: true });
            canSave = false;
        }
        if (!this.state.zoneuid) {
            this.setState({ showZoneUidError: true });
            canSave = false;
        }
        if (!this.state.location) {
            this.setState({ showLocationError: true });
            canSave = false;
        }
        if (!this.state.roomNumber) {
            this.setState({ showRoomNumberError: true });
            canSave = false;
        }
        if (!this.state.capacity) {
            this.setState({ showCapacityError: true });
            canSave = false;
        }


        if (canSave) {
            this.setState({
                showRoomUidError: false,
                showZoneUidError: false,
                showLocationError: false,
                showRoomNumberError: false,
                showCapacityError: false,
                showConfirmationDialog: true,

            });
        }
    }

    //Submits the Room Details
    _submitRoom() {
        debugger;
        let roomMaster = {
            "RoomUID": this.state.roomuid,
            "ZoneUID": this.state.zoneuid,
            "Location": this.state.location,
            "RoomNumber": this.state.roomNumber,
            "Capacity": Number(this.state.capacity),
            "ID": Number(this.state.selectedRoomID),
        };

        this._postRoom(roomMaster);
    }

    //Creates/Updates the Room Details
    async _postRoom(roomData) {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(roomData)
        };
        await fetch("Rooms", requestOptions).then((response) => {
            if (response.ok) {
                this.populateRoomsData();
            } else {
                this.setState({
                    showAlert: true
                });
                this.populateRoomsData();
            }
        })
    }

    //Deletes the Room Details
    _deleteRoom() {
        if (this.state.deletionComment) {
            fetch("Rooms/" + this.state.selectedRoomID + "?comment=" + this.state.deletionComment, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken,
                }),
            }).then(_ => {
                this.populateRoomsData();
            }).catch(console.log(error))
        }
        else {
            this.setState({ showCommentError: true });
        }
    }
}