import { Button } from 'bootstrap';
import React, { Component } from 'react';
import SpaceModal from '@gsk-space-components/space-modal'
import SpaceButton from '@gsk-space-components/button';
import SpaceTheme from '@gsk-space-components/space-theme';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';

import './../custom.css';

let accessToken = undefined;
let storedAncillaries=[];
let action="";
let storedSupply = [];
let decisionToRetrieve = "";
export class AncillariesBooking extends Component {
    static displayName = AncillariesBooking.name;

    //Default Constructor
    constructor(props) {
        super(props);
        this.state = {
            isAdmin:true,
            ancillaries: [],
            allAncillaries: [],
            selectedAncillaries: [],
            ancillariesKeywords: [],
            ancillariesMaster: [],
            supply:[],
            showAllAncillaries: true,
            showAddEditAncillary: false,
            selectedAncillaryID: "",
            updateAncillary: false,
            updatedAncillaryID: "",
            deletedAncillaryID: "",
            selectedancillary: "",
            actionStatus:"",
            selectedquantity: "",
            loading: false,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            showAncillaryIDError: false,
            showAncillaryError: false,
            showQuantityError: false,
            selectedancillaryIDError: false,
            deletionComment: "",
            showCommentError: false,
            showCurrentAncillary:false
        };

        this._submitAncillary = this._submitAncillary.bind(this);
        this._deleteAncillary = this._deleteAncillary.bind(this);
        this._validateAncillaryInput = this._validateAncillaryInput.bind(this);
    }

    //Component Did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://0c649c8d-4144-4538-a249-02bc7dcfaa25/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.getAllAncillariesData();
        this._getAncillariesKeywords();
    }

    //Render the page based on New/Edit Request
    render() {
        return (
            <div>
               
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllAncillaries &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                            {<button title="Create New Ancillary" className={"btn-add"} onClick={() => { this.setState({ showAllAncillaries: false, showAddEditAncillary: true, selectedAncillaryID: "", selectedancillary: "", selectedquantity: "" }); }}>Add Ancillary</button>}
                        </div>
                        <div className={"rootContent"}>{this.renderancillariesTable(this.state.ancillaries)}</div>
                    </div>}
                {!this.state.loading && this.state.showAddEditAncillary && <div>{this.renderNewAncillaryScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Ancillary"
                    body="Would you like to save this Ancillary?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitAncillary}
                    secondaryButtonFunction={() => { this.setState({ showAddEditAncillary: false, showAllAncillaries: true, showConfirmationDialog: false }) }}
                    id="confirm-submit-ancillary"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Ancillary"
                    body={<div>Would you like to delete this Ancillary?</div>}
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._deleteAncillary}
                    secondaryButtonFunction={() => { this.setState({ showAddEditAncillary: false, showAllAncillaries: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="confirm-delete-ancillary"
                />}
            </div>
        );
    }

    //Render the Existing Ancillary Details from the Inventory
    renderancillariesTable(ancillaries) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Ancillary ID</th>
                        <th>Ancillary</th>
                        <th>Quantity</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {(ancillaries === undefined || ancillaries === null || ancillaries.length === 0) && <tr><td colSpan={4}><div className={"no-record-error"}>No records found</div></td></tr>}
                    {ancillaries.map(ancillary =>
                        <tr>
                            <td>{ancillary.AncillaryID}</td>
                            <td>{ancillary.Ancillary}</td>
                            <td>{ancillary.Quantity}</td>
                            {<td><div>
                                <button title="Edit Equipment" className={"btn-edit"} onClick={() => {
                                    this.setState({
                                        updateAncillary: true,
                                        updatedAncillaryID: ancillary.ID,
                                        showAllAncillaries: false,
                                        showAddEditAncillary: true,
                                        selectedAncillaryID: ancillary.AncillaryID.toString(),
                                        selectedancillary: ancillary.Ancillary,
                                        selectedquantity: ancillary.Quantity
                                    });
                                }}>Edit</button>
                                <button title="Delete Equipment" className={"btn-delete"} onClick={() => {
                                    this.setState({
                                        showDeleteConfirmationDialog: true,
                                        deletedAncillaryID: ancillary.ID
                                    });
                                }}>Delete</button>
                            </div></td>}
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    //Render the new Ancillary Details Screen
    renderNewAncillaryScreen() {

        let listOfAncillaryIds = [];
        let listOfAncillaryNames = [...new Set(this.state.allAncillaries.map(itm => itm.Ancillary))];
        
        if (this.state.selectedancillary !== undefined && this.state.selectedancillary !== null && this.state.selectedancillary.length !== 0) {
            listOfAncillaryIds = this.state.allAncillaries.filter(itm => itm.Ancillary === this.state.selectedancillary).map(itm => itm.AncillaryID.toString());
        } else {
            listOfAncillaryIds = this.state.allAncillaries.map(itm => itm.AncillaryID.toString());
        }
        
        return (<div>
            <gsk-card image="/imagess/documentation.png" elevation="6">
                <div slot="content" className="gsk-card__content">
                    <div className="formcontainer">
                        <div className="formrow">
                            <div className="col-25">
                                <label >Ancillary</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={listOfAncillaryNames}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    
                                    placeholder={"Select Ancillary.."}
                                    value={this.state.selectedancillary}
                                    onChange={(_event, newValue) => {
                                        this.setState({
                                            selectedancillary: newValue, showAncillaryError: (newValue === undefined || newValue === "")
                                        })
                                    }}
                                    error={this.state.showAncillaryError}
                                    required
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Ancillary ID</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={listOfAncillaryIds}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Ancillary ID.."
                                    value={this.state.selectedAncillaryID}
                                    onChange={(_event, newValue) => { this.setState({ selectedAncillaryID: newValue, showAncillaryIDError: (newValue == undefined) }) }}
                                    error={this.state.showAncillaryIDError}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Quantity</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput name={"Quantity"} type="number" fullWidth={true} placeholder={"Enter Quantity.."} error={this.state.showQuantityError} required value={this.state.selectedquantity} onChange={(e) => {
                                    this.setState({ selectedquantity: (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.selectedquantity, showQuantityError: (!(Number(e.target.value) && e.target.value > 0) && !this.state.selectedquantity) });
                                }}></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <button className={"submitBtn"} onClick={this._validateAncillaryInput}>Add</button>
                            <button className={"cancelBtn"} onClick={() => {
                                this.setState({
                                    updateAncillary: false,
                                    showAddEditAncillary: false,
                                    showAllAncillaries: true,
                                    showAncillaryIDError: false,
                                    showAncillaryError: false,
                                    showQuantityError: false
                                });
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </gsk-card>
        </div >);
    }

    //Populate the Ancillary Details
    async populateAncillariesData() {
        const response = await fetch('Ancillaries', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, ancillaries: data, loading: false, showAllAncillaries: true, showAddEditAncillary: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedAncillaryID: "" });
        }
        else {
            this.setState({ isAdmin: false, ancillaries: [], loading: false, showAllAncillaries: true, showAddEditAncillary: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedAncillaryID: "" });
        }
    }

    //Gets the Ancillary Data from Inventory
    async getAllAncillariesData() {
        const response = await fetch('Ancillaries', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, allAncillaries: data });

            action = localStorage.getItem('Action');
            this.setState({ actionStatus: action, showCurrentAncillary: true })
            decisionToRetrieve = localStorage.getItem('UserFixedDecision');
            storedAncillaries = JSON.parse(localStorage.getItem("AncillariesBooking") || '{}');

            if (storedAncillaries !== null && storedAncillaries !== "" && Object.entries(storedAncillaries).length !== 0) {
                this.setState({ ancillaries: storedAncillaries, ancillariesMaster: storedAncillaries })
            } else if (action === "Add") {
                this.setState({ ancillaries: [] })
            }
            this.setState({ loading: false });
        }
        else {
            this.setState({ isAdmin: false, allAncillaries: []});
        }
    }

    //Gets all the keywords from the Inventory related to Ancillary
    async _getAncillariesKeywords() {
        const response = await fetch('Keywords/Ancillary', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const ancillariesData = await response.json();
            this.setState({ ancillariesKeywords: ancillariesData });
        } else {
            this.setState({ ancillariesKeywords: [] });
        }
    }

    //Validates the Input provided by User
    _validateAncillaryInput() {
        let canSave = true;
        if (!this.state.selectedancillary) {
            this.setState({ showAncillaryError: true });
            canSave = false;
        }
        if (!this.state.selectedAncillaryID) {
            this.setState({ selectedancillaryIDError: true });
            canSave = false;
        }
        if (!this.state.selectedquantity) {
            this.setState({ showQuantityError: true });
            canSave = false;
        }
        if (canSave) {
            this.setState({
                showAncillaryError: false,
                selectedancillaryIDError: false,
                showQuantityError: false,
                showConfirmationDialog: true
            });
        }
    }

    //Submits the Ancillary Details
    _submitAncillary() {
        if (this.state.updateAncillary) {
            for (let i = 0; i < this.state.ancillariesMaster.length; i++) {
                if (this.state.ancillariesMaster[i].ID === this.state.updatedAncillaryID) {
                    this.state.ancillariesMaster[i].AncillaryID = Number(this.state.selectedAncillaryID);
                    this.state.ancillariesMaster[i].Ancillary = this.state.selectedancillary;
                    this.state.ancillariesMaster[i].Quantity = this.state.selectedquantity;
                }
            }
        }
        else {
            this.state.ancillariesMaster.push({
                "Ancillary": this.state.selectedancillary,
                "AncillaryID": Number(this.state.selectedAncillaryID),
                "Quantity": this.state.selectedquantity
            });
        }
        this.state.updateAncillary = false;
        this._setAncillaries(this.state.ancillariesMaster);
        localStorage.removeItem('AncillariesBooking');
        localStorage.setItem("AncillariesBooking", JSON.stringify(this.state.ancillariesMaster));

    }

    _setAncillaries(ancillariesMaster) {
        let userRoles = getUserRoles();
        let hasAdminRole = userRoles.indexOf("Admin") > -1;
        this.setState({ isAdmin: hasAdminRole, ancillaries: this.state.ancillariesMaster, loading: false, showAllAncillaries: true, showAddEditAncillary: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedAncillaryID: "" });
    }

    //Deletes the Ancillary Detail from the storage
    _deleteAncillary() {
        
        for (let i = 0; i < this.state.ancillariesMaster.length; i++) {
            if (this.state.ancillariesMaster[i].ID === this.state.deletedAncillaryID) {
                this.state.ancillariesMaster.splice(i, 1);
            }
        }

        this._setAncillaries(this.state.ancillariesMaster);
        localStorage.removeItem('AncillariesBooking');
        localStorage.setItem("AncillariesBooking", JSON.stringify(this.state.ancillariesMaster));
    }
}