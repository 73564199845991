import { Button } from 'bootstrap';
import React, { Component } from 'react';
import './../custom.css';
import SpaceModal from '@gsk-space-components/space-modal'
import SpaceButton from '@gsk-space-components/button';
import SpaceTheme from '@gsk-space-components/space-theme';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';
import TableContainer from "./TableContainer";
import { defaultColumn } from 'react-table';
import ReactTable from "react-table";
import { Container } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

let accessToken = undefined;
export class ToolingInventory extends Component {
    static displayName = ToolingInventory.name;

    //Default Constructor
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            toolings: [],
            toolingTypeKeywords: [],
            showAllToolings: false,
            showAddEditTooling: false,
            selectedToolingID: 0,
            selectedToolingName: "",
            selectedToolingType: "",
            selectedDimension: "",
            selectedSetNumber: undefined,
            selectedShape: "",
            selectedEmbossingUpper: "",
            selectedEmbossingLower: "",
            selectedUpperPunches: undefined,
            selectedLowerPunches: undefined,
            selectedNumberOfDies: undefined,
            selectedProfileOrConcavity: "",
            showAlert: false,
            showToolingTypeError: false,
            showToolingNameError: false,
            showDimensionError: false,
            showSetNumberError: false,
            showShapeError: false,
            showEmbossingUpperError: false,
            showEmbossingLowerError: false,
            showUpperPunchesError: false,
            showLowerPunchesError: false,
            showProfileOrConcavityError: false,
            showDiesError: false,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            deletionComment: "",
            showCommentError: false,
            loading: true
        };

        this._submitTooling = this._submitTooling.bind(this);
        this._deleteTooling = this._deleteTooling.bind(this);
        this._validateToolingInput = this._validateToolingInput.bind(this);
    }

    //Exports the PDF Report
    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        const title = "Tooling Report";
        const headers = [["Tooling Name", "Tooling Type", "Set Number", "No of Upper Punches", "No of Lower Punches", "No of Dies"]];
        const data = this.state.toolings.map(tools => [tools.ToolingName, tools.ToolingType, tools.SetNumber, tools.UpperPunches, tools.LowerPunches, tools.NumberOfDies]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Tooling Report.pdf")
    }

    //Component Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://0c649c8d-4144-4538-a249-02bc7dcfaa25/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.populateToolingData();
        this._getToolingTypeKeywords();
    }

    //Renders the existing Data from Inventory
    renderToolingTable(toolings) {
        const data = this.state.toolings;
        const admin = this.state.isAdmin;
        let columns = [];
        if (admin) {
            columns = [
                {
                    Header: "Tooling Name",
                    accessor: "ToolingName"
                },
                {
                    Header: "Tooling Type",
                    accessor: "ToolingType"
                },
                {
                    Header: "Set Number",
                    accessor: "SetNumber"
                },
                {
                    Header: "No of Upper Punches",
                    accessor: "UpperPunches"
                },
                {
                    Header: "No of Lower Punches",
                    accessor: "LowerPunches"
                },
                {
                    Header: "Number of Dies",
                    accessor: "NumberOfDies"
                },
                {
                    width: 300,
                    Header: "Action",
                    accessor: "name",
                    Cell: (data) => (

                        <div>
                            <button title="Edit Equipment" className={"btn-edit"} onClick={() =>
                                this.setState({
                                    showAllToolings: false,
                                    showAddEditTooling: true,
                                    selectedToolingID: data.row.original.ID,
                                    selectedToolingType: data.row.original.ToolingType,
                                    selectedDimension: data.row.original.Dimensions,
                                    selectedSetNumber: data.row.original.SetNumber,
                                    selectedShape: data.row.original.Shape,
                                    selectedEmbossingUpper: data.row.original.EmbossingUpper,
                                    selectedEmbossingLower: data.row.original.EmbossingLower,
                                    selectedUpperPunches: data.row.original.UpperPunches,
                                    selectedLowerPunches: data.row.original.LowerPunches,
                                    selectedNumberOfDies: data.row.original.NumberOfDies,
                                    selectedProfileOrConcavity: data.row.original.ProfileOrConcavity,

                                })
                            }
                            >
                                Edit </button>
                            <button title="Delete Equipment" className={"btn-delete"} onClick={() => {
                                this.setState({
                                    showDeleteConfirmationDialog: true,
                                    selectedToolingID: data.row.original.ID,
                                    deletedToolingID: data.row.original.SetNumber
                                });
                            }}>Delete</button>
                        </div>
                    )
                }
            ];
        } else {
            columns = [
                {
                    Header: "Tooling Name",
                    accessor: "ToolingName"
                },
                {
                    Header: "Tooling Type",
                    accessor: "ToolingType"
                },
                {
                    Header: "Set Number",
                    accessor: "SetNumber"
                },
                {
                    Header: "No of Upper Punches",
                    accessor: "UpperPunches"
                },
                {
                    Header: "No of Lower Punches",
                    accessor: "LowerPunches"
                },
                {
                    Header: "Number of Dies",
                    accessor: "NumberOfDies"
                }
            ];
        }
        return (
            <div>
                 {columns!==undefined}
                <Container style={{ marginTop: 50 }}>
                <TableContainer columns={columns} data={data} />
                </Container>
            </div>
        );
    }


    //Renders New Screen
    renderNewToolingScreen() {
        return (<div>
            <div className="formcontainer">
                <div className="formrow">
                    <div className="col-25">
                        <label >Set Number</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} type="number" required placeholder={"Enter Set Number.."} value={this.state.selectedSetNumber} error={this.state.showSetNumberError}
                            onChange={(e) => { this.setState({ selectedSetNumber: (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.selectedSetNumber, showSetNumberError: (!(Number(e.target.value) && e.target.value > 0) && !this.state.selectedSetNumber) }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Tooling Type</label>
                    </div>
                    <div className="col-75">
                        <SpaceAutocomplete
                            id="basic-test"
                            options={this.state.toolingTypeKeywords.map(itm => itm.Keyword)}
                            getOptionLabel={(option) => option}
                            fullWidth={true}
                            placeholder="Select Tooling Type.."
                            value={this.state.selectedToolingType}
                            onChange={(_event, newValue) => { this.setState({ selectedToolingType: newValue, showToolingTypeError: (newValue === undefined || newValue === "") }) }}
                            error={this.state.showToolingTypeError}
                        />
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Dimensions</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Dimensions.."} value={this.state.selectedDimension} error={this.state.showDimensionError}
                            onChange={(e) => { this.setState({ selectedDimension: e.target.value, showDimensionError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Shape</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Shape.."} value={this.state.selectedShape} error={this.state.showShapeError}
                            onChange={(e) => { this.setState({ selectedShape: e.target.value, showShapeError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Embossing Upper</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Embossing Upper.."} value={this.state.selectedEmbossingUpper} error={this.state.showEmbossingUpperError}
                            onChange={(e) => { this.setState({ selectedEmbossingUpper: e.target.value, showEmbossingUpperError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Embossing Lower</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Embossing Lower.."} value={this.state.selectedEmbossingLower} error={this.state.showEmbossingLowerError}
                            onChange={(e) => { this.setState({ selectedEmbossingLower: e.target.value, showEmbossingLowerError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >No Of Upper Punches</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} type="number" required placeholder={"Enter No Of Upper Punches.."} value={this.state.selectedUpperPunches} error={this.state.showUpperPunchesError}
                            onChange={(e) => { this.setState({ selectedUpperPunches: (Number(e.target.value) > -1) ? e.target.value : this.state.selectedUpperPunches, showUpperPunchesError: (!(Number(e.target.value) > -1) && !this.state.selectedUpperPunches) }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >No Of Lower Punches</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} type="number" required placeholder={"Enter No Of Lower Punches.."} value={this.state.selectedLowerPunches} error={this.state.showLowerPunchesError}
                            onChange={(e) => { this.setState({ selectedLowerPunches: (Number(e.target.value) > -1) ? e.target.value : this.state.selectedLowerPunches, showLowerPunchesError: (!(Number(e.target.value) > -1) && !this.state.selectedLowerPunches) }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Number Of Dies</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} type="number" required  placeholder={"Enter Number Of Dies.."} value={this.state.selectedNumberOfDies} error={this.state.showDiesError}
                            onChange={(e) => { this.setState({ selectedNumberOfDies: (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.selectedNumberOfDies, showDiesError: (!(Number(e.target.value) && e.target.value > 0) && !this.state.selectedNumberOfDies) }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Profile/Concavity</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Profile/Concavity.."} value={this.state.selectedProfileOrConcavity} error={this.state.showProfileOrConcavityError}
                            onChange={(e) => { this.setState({ selectedProfileOrConcavity: e.target.value, showProfileOrConcavityError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <button className={"submitBtn"} onClick={this._validateToolingInput}>Submit</button>
                    <button className={"cancelBtn"} onClick={() => {
                        this.setState({
                            showAddEditTooling: false,
                            showAllToolings: true,
                            showToolingTypeError: false,
                            showDimensionError: false,
                            showSetNumberError: false,
                            showShapeError: false,
                            showEmbossingUpperError: false,
                            showEmbossingLowerError: false,
                            showUpperPunchesError: false,
                            showLowerPunchesError: false,
                            showProfileOrConcavityError: false,
                        });
                    }}>Cancel</button>
                </div>
            </div>
        </div >);
    }

    //Renders the page based on New/Edit screen
    render() {
        return (
            <div>
                <h2 id="tabelLabel" >Inventory - Tooling</h2>
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllToolings &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                            {this.state.isAdmin && <button title="Create New Tooling" className={"btn-add"} onClick={() => {
                                this.setState({
                                    showAllToolings: false,
                                    showAddEditTooling: true,
                                    selectedToolingType: "",
                                    selectedDimension: "",
                                    selectedSetNumber: undefined,
                                    selectedShape: "",
                                    selectedEmbossingUpper: "",
                                    selectedEmbossingLower: "",
                                    selectedUpperPunches: undefined,
                                    selectedLowerPunches: undefined,
                                    selectedNumberOfDies: undefined,
                                    selectedProfileOrConcavity: ""
                                });
                        }}>New Tooling</button>}
                        <button onClick={this.exportPDF} title="Download PDF" className={"btn-add"} >Download PDF</button>
                        </div>
                        <div className={"rootContent"}>{this.renderToolingTable(this.state.toolings)}</div>
                    </div>}
                {!this.state.loading && this.state.showAddEditTooling && <div>{this.renderNewToolingScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Tool"
                    body="Would you like to save this Tool?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitTooling}
                    secondaryButtonFunction={() => { this.setState({ showAddEditTooling: false, showAllToolings: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="saveToolDialog"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Tool"
                    body={<div><div>Would you like to delete this Tool?</div><div>
                        <OutlinedInput fullWidth={true} placeholder={"Enter reason.."} required multiline={true} rows={3} error={this.state.showCommentError}
                            onChange={(e) => { this.setState({ deletionComment: e.target.value, showCommentError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div></div>}
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._deleteTooling}
                    secondaryButtonFunction={() => { this.setState({ showAddEditTooling: false, showAllToolings: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="deleteToolDialog"
                />}
                {this.state.showAlert && <SpaceModal
                    open={this.state.showAlert}
                    titleText="Error Occured"
                    body="Error Occured. Please Contact the Administrator"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAlert: false }) }}
                    id="alertBox"
                />}
            </div>
        );
    }

    //Retrieves all the Tooling Data
    async populateToolingData() {
        const response = await fetch('Tooling', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, toolings: data, loading: false, showAllToolings: true, showAddEditTooling: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false });
        }
        else {
            this.setState({ isAdmin: false, toolings: [], loading: false, showAllToolings: true, showAddEditTooling: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false });
        }
    }

    //Gets all the Tooling type Keywords
    async _getToolingTypeKeywords() {
        const response = await fetch('Keywords/Tooling Type', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const toolingTypesData = await response.json();
            this.setState({ toolingTypeKeywords: toolingTypesData });
        }
        else {
            this.setState({ toolingTypeKeywords: [] });
        }
    }

    //Validates the Input provided by User
    _validateToolingInput() {
        let canSave = true;
        if (!this.state.selectedToolingType) {
            this.setState({ showToolingTypeError: true });
            canSave = false;
        }
        if (!this.state.selectedDimension) {
            this.setState({ showDimensionError: true });
            canSave = false;
        }
        if (!this.state.selectedSetNumber) {
            this.setState({ showSetNumberError: true });
            canSave = false;
        }
        if (!this.state.selectedShape) {
            this.setState({ showShapeError: true });
            canSave = false;
        }
        if (!this.state.selectedEmbossingUpper) {
            this.setState({ showEmbossingUpperError: true });
            canSave = false;
        }
        if (!this.state.selectedEmbossingLower) {
            this.setState({ showEmbossingLowerError: true });
            canSave = false;
        }
        if (Number(this.state.selectedLowerPunches) < 0) {
            this.setState({ showLowerPunchesError: true });
            canSave = false;
        }
        if (Number(this.state.selectedUpperPunches) < 0) {
            this.setState({ showUpperPunchesError: true });
            canSave = false;
        }
        if (!this.state.selectedProfileOrConcavity) {
            this.setState({ showProfileOrConcavityError: true });
            canSave = false;
        }

        if (canSave) {
            this.setState({
                showToolingTypeError: false,
                showDimensionError: false,
                showSetNumberError: false,
                showShapeError: false,
                showEmbossingUpperError: false,
                showEmbossingLowerError: false,
                showLowerPunchesError: false,
                showUpperPunchesError: false,
                showProfileOrConcavityError: false,
                showConfirmationDialog: true

            });
        }
    }

    //Submits the tooling details
    _submitTooling() {
        let toolingMaster = {
            "ToolingType": this.state.selectedToolingType,
            "SetNumber": Number(this.state.selectedSetNumber),
            "Dimensions": this.state.selectedDimension,
            "Shape": this.state.selectedShape,
            "EmbossingUpper": this.state.selectedEmbossingUpper,
            "EmbossingLower": this.state.selectedEmbossingLower,
            "LowerPunches": Number(this.state.selectedLowerPunches),
            "UpperPunches": Number(this.state.selectedUpperPunches),
            "ProfileOrConcavity": this.state.selectedProfileOrConcavity,
            "NumberOfDies": Number(this.state.selectedNumberOfDies),
            "ProfileOrConcavity": this.state.selectedProfileOrConcavity,
            "ID": this.state.selectedToolingID
        };

        this._postTooling(toolingMaster);
    }

    //Creates/Updates the Tooling Data
    async _postTooling(toolingData) {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(toolingData)
        };
        await fetch("Tooling", requestOptions).then((response) => {
            if (response.ok) {
                this.populateToolingData();
            } else {
                this.setState({
                    showAlert: true
                });
                this.populateToolingData();
            }
        })
    }

    //Deletes the Tooling Data
    _deleteTooling() {
        if (this.state.deletionComment) {
            fetch("Tooling/" + this.state.selectedToolingID + "?comment=" + this.state.deletionComment, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken,
                }),
            }).then(_ => {
                this.populateToolingData();
            }).catch(console.log);
        }
        else {
            this.setState({ showCommentError: true });
        }
    }
}