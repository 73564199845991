export const msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/63982aff-fb6c-4c22-973b-70e4acfb63e6',
        clientId: '0c649c8d-4144-4538-a249-02bc7dcfaa25',
        redirectUri: document.getElementById('root').baseURI
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
        secureCookies: false
    }
};