import { Button } from 'bootstrap';
import React, { Component } from 'react';
import './../custom.css';


let accessToken = undefined;
export class Landing extends Component {
    static displayName = Landing.name;

    //Default Constructor
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            proceed: false
        };

    }

    //Redirect to Equipment Screen
    submit = () => {
        this.setState({ proceed: true });
    }

    //Renders the Page
    render() {
        if (this.state.proceed) {
            return (
                window.location.replace('/inventory-equipments')
            )

        } else {
            return (
                <div>
                    <button title="Pilot-Plant" className={"btn-landing"}>Ware Pilot Plant <br />Campaign Booking Application</button>
                    <div style={{ clear: 'right', marginTop: '10px', textAlign: "center" }}>
                        <p className={"paragraph"}>WELCOME to the Ware Pilot Plant Campaign Booking Application which serves as an<br />entry point for pilot plant users to book equipment and facilities in Ware Pilot Plant.This <br />
                            application also facilitates the administrators to manage inventory of equipment, rooms<br />  and relevant ancillaries.
                        </p>

                            <div className='disclaimer-wrapper'>
                                <div className='disclaimer-container'>
                                    <h3 className='disclaimer-head'>DISCLAIMER</h3>
                                    <p className='disclaimer-para'>This System contains confidential business information that is owned or licensed by GlaxoSmithKline and is intended for access and use by authorized individuals only. This confidential business information must not be disclosed to unauthorized individuals. All access to and use of this System is subject to monitoring and may be used for prosecution.</p>
                                </div>
                            </div>


                        <p>
                            <br />For support and feedback, please contact the business owner:<span style={{ color: 'teal', fontWeight: '600' }}> Gavin Mitchell<br />(Gavin.S.Mitchell@gsk.com)</span> or the service owner:<span style={{ color: 'teal', fontWeight: '600' }}> Neelam Sonker (Neelam.X.Sonker@gsk.com).</span>
                            <br /><br /><a href= "https://gsk.service-now.com/">Click here</a> to raise an issue ticket via Service Now</p>
                        <button title="Click to Proceed" className={"btn-proceed"} onClick={this.submit}>Click to proceed</button>
                    </div>
                </div>
            );
        }
    }


}