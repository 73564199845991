import React, { Component } from 'react';
import { msalAuth, getAccessToken } from '../msal/MsalAuthProvider'

let accessToken = "";
export class FetchData extends Component {
    static displayName = FetchData.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true };
    }


    async componentDidMount() {
        //accessToken = getAccessToken();
        const accessTokenRequest = {
            scopes: ["user.read"]
        }
        accessToken = await msalAuth.acquireTokenSilent(accessTokenRequest);
        if (accessToken) {
            console.log(accessToken);
            let account = msalAuth.getAccount();
            console.log(JSON.stringify(account));
            this.populateWeatherData();
        }
        else {
            msalAuth.loginRedirect();
        }
    }

    static renderForecastsTable(forecasts) {
        return (

            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Roles</th>

                    </tr>
                </thead>
                <tbody>
                    {forecasts.map(forecast =>
                        <tr>
                            <td>{forecast.Type}</td>
                            <td>{forecast.Value}</td>
                            <td>{forecast.ValueType}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : <div>{JSON.stringify(accessToken)} </div>;

        return (
            <div>
                <h1 id="tabelLabel" >Weather forecast</h1>
                <p>This component demonstrates fetching data from the server.</p>
                {contents}
            </div>
        );
    }

    async populateWeatherData() {
        const response = await fetch('weatherforecast');
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });
    }
}
