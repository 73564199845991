import { Button } from 'bootstrap';
import React, { Component } from 'react';
import SpaceModal from '@gsk-space-components/space-modal'
import SpaceButton from '@gsk-space-components/button';
import SpaceTheme from '@gsk-space-components/space-theme';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';
import { parseJSON } from 'jquery';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import './../custom.css';

let accessToken = undefined;
let storedSupply = [];
let storedNames = [];
let campaignIDExist =  false;
let action = "";
let campaignIDToRetrieve = "";
let routesToRetrieve = "";
let decisionToRetrieve = "";

export class SupplyRequirementBooking extends Component {
    static displayName = SupplyRequirementBooking.name;

    //Submits the Supply Requirement
    onSubmit = () => {
        if (this.state.supply !== "" && this.state.supply !== null) {
            if (Object.entries(this.state.supply).length > 0) {
                this.setState({ showAlert: true });
            } else {
                this.setState({ showAllSupply: false, showAddEditSupply: true, showAlert: false });
            }
        }
    }

    //Changes the Date Format to GSK
    changeDateFormat = (date) => {
        let dateFormat = "";
        var day = new Date(date).getDate();
        if (day < 10) {
            day = '0' + day;
        }
        dateFormat = day + '-' + (new Date(date).toLocaleString('default', { month: 'short' })) + '-' + new Date(date).getFullYear();
        return dateFormat.toString();
    }

    //Changes the date Format from Database
    changeToDate = (date) => {
        let newdate = new Date(date);
        return newdate;
    }

    //Default Constructor
    constructor(props) {
        super(props);

        const newdate = new Date();
        this.state = {
            isAdmin: true,
            supply: [],
            supplyMaster: [],
            showAllSupply: true,
            showAddEditSupply: false,
            selectedSupplyID: 0,
            campaignID: "",
            requestorName: "",
            loading: true,
            updateSupply: false,
            updatedCampaignID: "",
            deletedCampaignID: "",
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            showCampaignIdError: false,
            showRequestorNameError: false,
            deletionComment: "",
            showCommentError: false,
            showAlert: false,
            showCampaignIDAlert: false,
            filteredRequestors: [],
            compoundKeywords: [],
            campaignTypeKeywords: [],
            occupationalHazardKeywords: [],
            notationKeywords: [],
            allToolings: [],
            booking: [],
            showSearchLoading: false,
            showRequestorError: false,
            product_compoundID: "",
            compoundType: "",
            campaignType: "",
            processBy: "",
            includesPlacebo: false,
            requestDate: newdate,
            safetyBookletno: "",
            occupationHazard: "",
            notation: "",
            showProduct_CompoundIDError: false,
            showCompoundTypeError: false,
            showCampaignTypeError: false,
            showProcessByError: false,
            showRequestorNameError: false,
            showIncludesPlaceboError: false,
            showRequestDateError: false,
            showSafetyBookletNoError: false,
            showOccupationHazardError: false,
            showNotationError: false,
            loggedInUser: ""
        };

        this._submitSupply = this._submitSupply.bind(this);
        this._deleteSupply = this._deleteSupply.bind(this);
        this._validateSupplyInput = this._validateSupplyInput.bind(this);
    }

    //Component Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://0c649c8d-4144-4538-a249-02bc7dcfaa25/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.setVal();
        this.getSinequaToken();
        this.populateSupplyData();
        this._getCampaignTypeKeywords();
        this._getNotationKeywords();
        this._getOccupationalHazardKeywords();
        this._getProductKeywords();
        this.loadBookingData();
    }

    //Renders the New/Edit Screens
    render() {
        return (
            <div>
               
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllSupply &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                            {<button title="Create New Supply" className={"btn-add"} onClick={() => {
                                this.onSubmit();
                            }}>Add Supply</button>}
                        </div>
                        {this.state.showAlert && <SpaceModal
                            open={this.state.showAlert}
                            titleText="Supply Added"
                            body="You have already added Supply Requirement. Please add process."
                            mainButtonLabel="Close"
                            mainButtonFunction={() => { this.setState({ showAllSupply: true, showAlert: false }) }}
                            id="example-3"
                        />}
                        <div className={"rootContent"}>{this.rendersupplyTable(this.state.supply)}</div>
                    </div>}

                {!this.state.loading && this.state.showAddEditSupply && <div>{this.renderNewSupplyScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Supply"
                    body="Would you like to save this Supply?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitSupply}
                    secondaryButtonFunction={() => { this.setState({ showAddEditSupply: false, showAllSupply: true, showConfirmationDialog: false }) }}
                    id="confirm-submit-supply"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Supply"
                    body={<div>Would you like to delete this Supply?</div>}
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._deleteSupply}
                    secondaryButtonFunction={() => {
                        this.setState({
                            showAddEditSupply: false,
                            showAllSupply: true,
                            showConfirmationDialog: false,
                            showDeleteConfirmationDialog: false
                        })
                    }}
                    id="confirm-delete-supply"
                />}
                {this.state.showCampaignIDAlert && <SpaceModal
                    open={this.state.showCampaignIDAlert}
                    titleText="Error Occured"
                    body="The Campaign ID is already taken. Please Contact the Administrator"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showCampaignIDAlert: false }) }}
                    id="alertBox"
                />}
            </div>
        );
    }

    //Renders the existing Data
    rendersupplyTable(supply) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel" >
                <thead>
                    <tr>
                        <th>Campaign ID</th>
                        <th>Requestor Name</th>
                        <th>Product/Compound ID</th>
                        <th>Compound Type</th>
                        <th>Campaign Type</th>
                        <th>Process By</th>
                        <th>Includes Placebo</th>
                        <th>Request Date</th>
                        <th>Safety Booklet Number</th>
                        <th>Occupation Hazard</th>
                        <th>Notation</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {(supply === undefined || supply === null || supply.length === 0) && <tr><td colSpan={ 19 }><div className={"no-record-error"}>No records found</div></td></tr>}
                    {supply.map(supply =>
                        <tr>
                            <td>{supply.Campaign_ID}</td>
                            <td>{supply.RequestorName}</td>
                            <td>{supply.Compound_ID}</td>
                            <td>{supply.Compound_Type}</td>
                            <td>{supply.Campaign_Type}</td>
                            <td>{supply.ProcessBy}</td>
                            <td>{supply.IncludesPlacebo ? "Yes" : "No"}</td>
                            <td>{this.changeDateFormat(supply.RequestDate)}</td>
                            <td>{supply.SafetyBookletNumber}</td>
                            <td>{supply.OccupationHazard}</td>
                            <td>{supply.Notation}</td>

                            {<td><div>
                                <button title="Edit Supply" className={"btn-edit"} onClick={() => {
                                    this.setState({
                                        updateSupply: true,
                                        showAllSupply: false,
                                        showAddEditSupply: true,
                                        updatedCampaignID: supply.Campaign_ID,
                                        selectedSupplyID: supply.ID,
                                        campaignID: supply.Campaign_ID,
                                        requestorName: supply.RequestorName,
                                        product_compoundID: supply.Compound_ID,
                                        compoundType: supply.Compound_Type,
                                        campaignType: supply.Campaign_Type,
                                        processBy: supply.ProcessBy,
                                        includesPlacebo: supply.IncludesPlacebo ? "Yes" : "No",
                                        requestDate: this.changeToDate(supply.RequestDate),
                                        safetyBookletno: supply.SafetyBookletNumber,
                                        occupationHazard: supply.OccupationHazard,
                                        notation: supply.Notation
                                    });
                                }}>Edit</button>
                                <button title="Delete Supply" className={"btn-delete"} onClick={() => {
                                    this.setState({
                                        showDeleteConfirmationDialog: true,
                                        selectedSupplyID: supply.ID,
                                        deletedCampaignID: supply.CampaignID
                                    });
                                }}>Delete</button>
                            </div></td>}
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    //Renders the new Supply Requirement Screen
    renderNewSupplyScreen() {
        
        return (<div>
            <gsk-card image="/imagess/documentation.png" elevation="6">
                <div slot="content" className="gsk-card__content">
                    <div className="formcontainer">
                        <div className="formrow">
                            <div className="col-25">
                                <label >Campaign ID</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} required placeholder={"Enter Campaign ID.."} value={this.state.campaignID} error={this.state.showCampaignIdError}
                                    onChange={(e) => { this.setState({ campaignID: e.target.value, showCampaignIdError: (e.target.value === undefined || e.target.value === "") }); }}
                                ></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Requestor Name</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="nsr-requestor"
                                    options={this.state.filteredRequestors}
                                    getOptionLabel={(option) => option}
                                    loading={this.state.showSearchLoading}
                                    fullWidth={true}
                                    placeholder={"Select Requestor.."}
                                    value={this.state.requestorName}
                                    error={this.state.showRequestorError}
                                    onInputChange={(_event, newValue) => { this._searchRequestor(newValue); this.setState({ filteredRequestors: [] }) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Product/Compound ID</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={this.state.compoundKeywords.map(itm => itm.Keyword)}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Product/Compound ID.."
                                    value={this.state.product_compoundID}
                                    onChange={(_event, newValue) => { this.setState({ product_compoundID: newValue, showProduct_CompoundIDError: (newValue === undefined || newValue === "") }) }}
                                    error={this.state.showProduct_CompoundIDError}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Compound Type</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={["OSD", "Inhaled"]}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Compound Type.."
                                    value={this.state.compoundType}
                                    error={this.state.showCompoundTypeError}
                                    onChange={(_event, newValue) => { this.setState({ compoundType: newValue, showCompoundTypeError: (newValue === undefined || newValue === "") }) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Campaign Type</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={this.state.campaignTypeKeywords.map(itm => itm.Keyword)}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Campaign Type.."
                                    value={this.state.campaignType}
                                    error={this.state.showCampaignTypeError}
                                    onChange={(_event, newValue) => { this.setState({ campaignType: newValue, showCampaignTypeError: (newValue === undefined || newValue === "") }) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label>Process By</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={["Batch", "UnitOperation"]}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Process By.."
                                    value={this.state.processBy}
                                    error={this.state.showProcessByError}
                                    onChange={(_event, newValue) => { this.setState({ processBy: newValue, showProcessByError: (newValue === undefined || newValue === "") }) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Includes Placebo?</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={["Yes", "No"]}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Please select.."
                                    value={this.state.includesPlacebo}
                                    error={this.state.showIncludesPlaceboError}
                                    onChange={(_event, newValue) => { this.setState({ includesPlacebo: newValue, showIncludesPlaceboError: (newValue === undefined || newValue === "") }) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label>Request Date</label>
                            </div>
                            <div className="col-75">
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Please select a date"
                                    selected={this.state.requestDate}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Safety Booklet Number</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} required placeholder={"Enter Safety Booklet Number.."} value={this.state.safetyBookletno} error={this.state.showSafetyBookletNoError}
                                    onChange={(e) => { this.setState({ safetyBookletno: e.target.value, showSafetyBookletNoError: (e.target.value === undefined || e.target.value === "") }); }}
                                ></OutlinedInput>
                            </div>
                        </div>

                        <div className="formrow">
                            <div className="col-25">
                                <label>Occupation Hazard</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={this.state.occupationalHazardKeywords.map(itm => itm.Keyword)}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Occupation Hazard.."
                                    value={this.state.occupationHazard}
                                    error={this.state.showOccupationHazardError}
                                    onChange={(_event, newValue) => { this.setState({ occupationHazard: newValue, showOccupationHazardError: (newValue === undefined || newValue === "") }) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label>Notation</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={this.state.notationKeywords.map(itm => itm.Keyword)}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Notation.."
                                    value={this.state.notation}
                                    error={this.state.showNotationError}
                                    onChange={(_event, newValue) => { this.setState({ notation: newValue, showNotationError: (newValue === undefined || newValue === "") }) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <button className={"submitBtn"} onClick={this._validateSupplyInput}>Add</button>
                            <button className={"cancelBtn"} onClick={() => {
                                this.setState({
                                    showAddEditSupply: false,
                                    showAllSupply: true,
                                    showCampaignIdError: false,
                                    showRequestorNameError: false,
                                    showProduct_CompoundIDError: false,
                                    showCompoundTypeError: false,
                                    showCampaignTypeError: false,
                                    showProcessByError: false,
                                    showIncludesPlaceboError: false,
                                    showRequestDateError: false,
                                    showSafetyBookletNoError: false,
                                    showOccupationHazardError: false,
                                    showNotationError: false,
                                    showBatchTypeError: false,
                                    showBatchScaleError: false,
                                    showNoOfBatchesError: false,
                                    showBatchSizeError: false,
                                    showReleaseDateError: false,
                                    showDueDateError: false,
                                    showDropDeadDateError: false
                                });
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </gsk-card>
        </div >);
    }

    //Get the Requestor Value from Sinequa Search API
    _searchRequestor(newValue) {
        if (newValue == "") {
            this.setState({ showRequestorError: true, filteredRequestors: [] });
        }
        else {
            this.setState({ showSearchLoading: true, requestorName: newValue, showRequestorError: false });
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem("peoplesearch.accesstoken"));
            
            let queryParams = {
                "name": "enterprise-search-query",
                "text": newValue,
                "select": "[{\"expression\":\"treepath: (`People`: `/People/*`)\",\"facet\":\"Treepath\"}]"
            }

            let requestParams = {
                "app": "EnterpriseSearchApp",
                "query": queryParams
            }

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(requestParams)
            };
            fetch("https://api.gsk.com/tools/search/sinequa/query", requestOptions)
                .then(response => response.text())
                .then(result => {
                    let formattedResult = parseJSON(result);
                    let options = [];
                    if (formattedResult.methodresult) {
                        for (var i in formattedResult.records) {
                            let entries = formattedResult.records[i];
                            options.push(entries.title);
                        }
                    }
                    this.setState({ filteredRequestors: options });
                    this.setState({ showSearchLoading: false });

                })
                .catch(error => console.log('error', error));
        }
    }

    //Gets the Sinequa Token
    async getSinequaToken() {
        const response = await fetch('SinequaToken', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.text();
            let authResp = parseJSON(data);
            sessionStorage.setItem("peoplesearch.accesstoken", authResp.access_token);
            this.setState({
                loading: false
            });
        }
        else {
            this.setState({
                loading: false
            });
        }
    }

    //Gets the Supply Requirement Details
    async populateSupplyData() {
        const response = await fetch('Supply', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, supply: data, loading: false, showAllSupply: true, showAddEditSupply: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedSupplyID: 0 });
        }
        else {
            this.setState({ isAdmin: false, supply: [], loading: false, showAllSupply: true, showAddEditSupply: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedSupplyID: 0 });
        }
    }

    //Gets the Campaign Type Keywords
    async _getCampaignTypeKeywords() {
        const response = await fetch('Keywords/Campaign Type', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const campaignTypeData = await response.json();
            this.setState({ campaignTypeKeywords: campaignTypeData });
        }
        else {
            this.setState({ campaignTypeKeywords: [] });
        }
    }

    //Gets the Occupational Hazard Keywords
    async _getOccupationalHazardKeywords() {
        const response = await fetch('Keywords/Occupation Hazard', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const occupationHazardData = await response.json();
            this.setState({ occupationalHazardKeywords: occupationHazardData });
        }
        else {
            this.setState({ occupationalHazardKeywords: [] });
        }
    }

    //Gets the Notation Keywords
    async _getNotationKeywords() {
        const response = await fetch('Keywords/Notation', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const notationData = await response.json();
            this.setState({ notationKeywords: notationData });
        }
        else {
            this.setState({ notationKeywords: [] });
        }
    }

    //Gets the Compound Type Keywords
    async _getProductKeywords() {
        const response = await fetch('Keywords/Product or Compound ID', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const compoundData = await response.json();
            this.setState({ compoundKeywords: compoundData });
        }
        else {
            this.setState({ compoundKeywords: [] });
        }
    }

    //Validates the Input provided by User
    _validateSupplyInput() {
        let canSave = true;
        if (!this.state.campaignID) {
            this.setState({ showCampaignIdError: true });
            canSave = false;
        }
        if (!this.state.requestorName) {
            this.setState({ showRequestorNameError: true });
            canSave = false;
        }
        if (!this.state.product_compoundID) {
            this.setState({ showProduct_CompoundIDError: true });
            canSave = false;
        }
        if (!this.state.compoundType) {
            this.setState({ showCompoundTypeError: true });
            canSave = false;
        }
        if (!this.state.campaignType) {
            this.setState({ showCampaignTypeError: true });
            canSave = false;
        }
        if (!this.state.processBy) {
            this.setState({ showProcessByError: true });
            canSave = false;
        }
        if (!this.state.includesPlacebo) {
            this.setState({ showIncludesPlaceboError: true });
            canSave = false;
        }
        if (!this.state.requestDate) {
            this.setState({ showRequestDateError: true });
            canSave = false;
        }
        if (!this.state.safetyBookletno) {
            this.setState({ showSafetyBookletNoError: true });
            canSave = false;
        }
        if (!this.state.occupationHazard) {
            this.setState({ showOccupationHazardError: true });
            canSave = false;
        }
        if (!this.state.notation) {
            this.setState({ showNotationError: true });
            canSave = false;
        }
        if (canSave) {
            this.setState({
                showCampaignIdError: false,
                showProduct_CompoundIDError: false,
                showCompoundTypeError: false,
                showCampaignTypeError: false,
                showProcessByError: false,
                showRequestorNameError: false,
                showIncludesPlaceboError: false,
                showRequestDateError: false,
                showSafetyBookletNoError: false,
                showOccupationHazardError: false,
                showNotationError: false,
                showConfirmationDialog: true
            });
        }
    }

    //Checks whether any existing Campaign present for the selected Campaign
    campaignIDCheck(campaignID) {

        let ExistingCampaign = this.state.booking.filter(itm => itm.Campaign_ID === campaignID);
        if (ExistingCampaign.length > 0) {
            campaignIDExist = true;
        }
    }

    //Submits the Supply Requirement
    _submitSupply() {
        if (this.state.updateSupply) {
            this.campaignIDCheck(this.state.campaignID);
            if (this.state.supplyMaster[0].Campaign_ID !== this.state.campaignID && campaignIDExist) {
                this.setState({
                    showCampaignIDAlert: true
                });
                campaignIDExist = false;
                this.props.history.push('/booking-request');
            }
            else {
                this.state.supplyMaster[0].Campaign_ID = this.state.campaignID;
                this.state.supplyMaster[0].RequestorName = this.state.requestorName;
                this.state.supplyMaster[0].ID = this.state.selectedSupplyID;
                this.state.supplyMaster[0].Compound_ID = this.state.product_compoundID;
                this.state.supplyMaster[0].Compound_Type = this.state.compoundType;
                this.state.supplyMaster[0].Campaign_Type = this.state.campaignType;
                this.state.supplyMaster[0].ProcessBy = this.state.processBy;
                this.state.supplyMaster[0].IncludesPlacebo = this.state.includesPlacebo === "Yes" ? true : false;
                this.state.supplyMaster[0].RequestDate = this.changeDateFormat(this.state.requestDate);
                this.state.supplyMaster[0].SafetyBookletNumber = this.state.safetyBookletno;
                this.state.supplyMaster[0].OccupationHazard = this.state.occupationHazard;
                this.state.supplyMaster[0].Notation = this.state.notation;
            }
        }
        else {
            this.state.supplyMaster.push({
                "Campaign_ID": this.state.campaignID,
                "RequestorName": this.state.requestorName,
                "Compound_ID": this.state.product_compoundID,
                "Compound_Type": this.state.compoundType,
                "Campaign_Type": this.state.campaignType,
                "ProcessBy": this.state.processBy,
                "IncludesPlacebo": this.state.includesPlacebo === "Yes" ? true : false,
                "RequestDate": this.changeDateFormat(this.state.requestDate),
                "SafetyBookletNumber": this.state.safetyBookletno,
                "OccupationHazard": this.state.occupationHazard,
                "Notation": this.state.notation,
            });
        }

        this.state.updateSupply = false;
        this._setSupply(this.state.supplyMaster);
        localStorage.removeItem('SupplyBooking');
        localStorage.setItem("SupplyBooking", JSON.stringify(this.state.supplyMaster));
    }

    _setSupply(supplyMaster) {
        let userRoles = getUserRoles();
        let hasAdminRole = userRoles.indexOf("Admin") > -1;
        this.setState({ isAdmin: hasAdminRole, supply: supplyMaster, loading: false, showAllSupply: true, showAddEditSupply: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedSupplyID: 0 });
    }

    //Sets all the tab data upfront
    setVal() {
        action = localStorage.getItem('Action');
        campaignIDToRetrieve = localStorage.getItem('CampaignId');
        routesToRetrieve = localStorage.getItem('ProdctionRoutes');
        decisionToRetrieve = localStorage.getItem('UserFixedDecision');
        storedSupply = JSON.parse(localStorage.getItem("SupplyBooking") || '{}');

        if ((storedSupply !== null && storedSupply !== "" && Object.entries(storedSupply).length !== 0)) {
            this.setState({ supply: storedSupply, supplyMaster: storedSupply, loading: false })
        } else if (action === "Add") {

            this.setState({ supply: [], loading: false })
        } else if (action === "Edit" || action === "Template") {
            //Supply Master Storage
            this.setState({ supply: JSON.parse(campaignIDToRetrieve), supplyMaster: JSON.parse(campaignIDToRetrieve) });
            localStorage.removeItem('SupplyBooking');
            localStorage.setItem("SupplyBooking", JSON.stringify(this.state.supplyMaster));

            this.setState({ supply: JSON.parse(campaignIDToRetrieve), supplyMaster: JSON.parse(campaignIDToRetrieve) });
            localStorage.removeItem('SupplyBooking');
            localStorage.setItem("SupplyBooking", JSON.stringify(this.state.supplyMaster));

            localStorage.removeItem('InformationBooking');
            localStorage.setItem("InformationBooking", JSON.stringify(this.state.supplyMaster));

            //Process Master Storage
            let routesdetails = JSON.parse(routesToRetrieve);
            let decisiondetails = JSON.parse(decisionToRetrieve);
            let combinedProcessDetails = [];
            for (let i = 0; i < routesdetails.length; i++) {
                combinedProcessDetails.push({
                    "ID": decisiondetails[i].ID,
                    "ProcessUID": routesdetails[i].Process_UID,
                    "OperatorAssigned": decisiondetails[i].No_of_Operator,
                    "StepIndex": routesdetails[i].Step_Index,
                    "DurationPerBatch": routesdetails[i].Dur_Days_Per_Batch,
                    "EquipmentUID": decisiondetails[i].Equipment_Assigned,
                    "EquipmentChangePart": decisiondetails[i].ChangePart_Assigned,
                    "RequiresFlexibleRoom": decisiondetails[i].Requires_Flexible_Room,
                    "EquipmentName": decisiondetails[i].Equipent_Name,
                    "RoomUID": decisiondetails[i].Room_Assigned,
                    "RoomLocation": decisiondetails[i].Room_Location,
                    "RoomNumber": decisiondetails[i].Room_Number,
                    "Id": decisiondetails[i].ID
                });
            }

            localStorage.removeItem('ProcessBookings');
            localStorage.setItem("ProcessBookings", JSON.stringify(combinedProcessDetails));
            this.setState({ loading: false })
        }
    }

    //Loads all the Campaign Related Data
    async loadBookingData() {
        const response = await fetch('Booking/GetAllCampaigns', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const bookingData = await response.json();
            this.setState({ booking: bookingData, loading: false, showDeleteConfirmationDialog: false });
        }
        else {
            this.setState({ booking: [], loading: false, showDeleteConfirmationDialog: false });
        }
    }

    //Deletes the Supply details from the local Storage
    _deleteSupply() {
        
        for (let i = 0; i < this.state.supplyMaster.length; i++) {
            if (this.state.supplyMaster[i].CampaignID === this.state.deletedCampaignID) {
                this.state.supplyMaster.splice(i, 1);
            }
        }

        this._setSupply(this.state.supplyMaster);
        localStorage.removeItem('SupplyBooking');
        localStorage.setItem("SupplyBooking", JSON.stringify(this.state.supplyMaster));
        localStorage.removeItem('InformationBooking');
        
        this.setState({
            campaignID: "",
            requestorName: "",
            product_compoundID: "",
            compoundType: "",
            campaignType: "",
            processBy: "",
            includesPlacebo: false,
            safetyBookletno: "",
            occupationHazard: "",
            notation: ""
        });
    }
}